.research-team-pagination {
  width: 50%;
  height: 0.6rem;
  border-radius: 1rem;
  background-color: rgba(24, 32, 29, 0.1) !important;
  overflow: hidden;
  max-width: 37.6rem;
  left: 0;
  right: 0;
  top: unset !important;
  bottom: 2.4rem;
  margin: 0 auto;

  .swiper-pagination-progressbar-fill {
    border-radius: 1rem;
    background-color: #153128 !important;
  }
}

@media (max-width: 1280px) {
  .research-team-pagination {
    max-width: 24rem;
  }
}

@media (max-width: 992px) {
  .research-team-pagination {
    max-width: 20rem;
  }
}

@media (max-width: 448px) {
  .research-team-pagination {
    max-width: 14rem;
  }
}
